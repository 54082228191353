import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { ModelTrim } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      img: {
        maxWidth: 80,
        maxHeight: 40,
        width: '100%',
        height: '100%',
      },
    }
  ),
);

interface Props {
  trim?: ModelTrim;
  placeholderImage?: string;
  size?: number;
  color?: number;
}

const TrimImage: React.FC<Props> = ({ size, trim, color, placeholderImage }) => {
  const classes = useStyles();
  if (!trim || typeof color === 'undefined') {
    return null;
  }

  const styles = size ? {
    maxWidth: size,
    maxHeight: size / 2,
    width: size,
    height: size / 2,
  } : {};

  let colorIndex = 0;
  for (let i = 0; i < trim.exteriorColors.length; i++) {
    if (color === trim.exteriorColors[ i ].id) {
      colorIndex = i;
      break;
    }
  }

  const imgPath = trim.exteriorColors[ colorIndex ].images.md ? trim.exteriorColors[ colorIndex ].images.md : placeholderImage;


  const imgUrl = `${process.env.CDN_BASE_URL_CARS}/${imgPath}`;

  return (
    <img className={classes.img} alt={trim.name} src={imgUrl} style={styles} />
  );
};

export default TrimImage;