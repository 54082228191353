import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Container,
  Link as MuiLink,
  Typography,
  Box,
  Breadcrumbs,
} from "@material-ui/core";
import { graphql, Link } from "gatsby";
import { CarMake, CarModel, ModelTrim } from "leasemojo-common";
import Layout from '../components/Layout';
import TrimGrid from "../components/CarGrid/TrimGrid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0),
      },
      icon: {
        marginRight: theme.spacing(4),
      }
    }
  ),
);


interface Props {
  data: {
    car: CarMake;
    model: CarModel;
    allTrim: {
      nodes: ModelTrim[]
    }
  }
}

const ListTrims: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  return (
    <Layout contentClassName={classes.root} title={`${data.car.name} ${data.model.name}`}>
      <Container maxWidth="md">
        <Typography variant="h5" paragraph align="center">{data.car.name} {data.model.name}</Typography>
        <Box mb={1} display="flex" justifyContent="center">
          <Breadcrumbs>
            <MuiLink color="inherit" component={Link} to={'/cars'}>Cars</MuiLink>
            <MuiLink color="inherit" component={Link} to={`/cars/${data.car.slug}`}>{data.car.name}</MuiLink>
            <Typography color="textPrimary">{data.model.name}</Typography>
          </Breadcrumbs>
        </Box>
        <TrimGrid model={data.model} trims={data.allTrim.nodes} itemUrl={`/cars/${data.car.slug}/${data.model.slug}/{id}/specs`} />
      </Container>
    </Layout>
  );
};

export default ListTrims;

export const query = graphql`
  query($carId: String!, $modelId: String!) {
    car(id: { eq: $carId }) {
      name
      logoUrl
      slug
    }
    model(id: { eq: $modelId }) {
      id
      imageUrl
      placeholderImage
      name
      slug
    }
    allTrim(
      filter: { modelId: { eq: $modelId } }
      sort: { fields: msrp, order: ASC }
    ) {
      nodes {
        id
        originalId
        name
        msrp
        slug,
        defaultColors {
          exteriorColorId
          interiorColorId
        }
        exteriorColors {
          images {
            md
            sm
            xsm
          }
          color
          msrp
          name
          rgbHex,
          id,
        }
        interiorColors {
          id
          msrp
          name
        }
      }
    }
  }
`
